import "./flag/sprite-flags-24x24.css";
import "./material-design-iconic-font/css/material-design-iconic-font.min.css";
import "./weather-icons/css/weather-icons.min.css";
import "./react-select/react-select.css";
import "./animate.css";
import "./bootstrap-rtl.css";
import "./loader.css";
import 'react-notifications/lib/notifications.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'styles/bootstrap.scss'
import 'styles/app.scss';
import 'styles/app-rtl.scss';